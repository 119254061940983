<template>
  <section id="call-to-action">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-8">
          <h3>En CAPIVE queremos apoyarte</h3>
          <p>Porque somos un equipo de Abogados con Capacidad, Integridad, Valor y Experiencia ocupados en proteger los mejores intereses de nuestro clientes.</p>
        </div>
        <div class="col-12 col-md-4 align-self-center t-center">
          <a href="#cta-contact-form" class="btn btn-primary"
            @click="toggleForm">Quiero una cita</a>
        </div>
      </div>

      <div id="cta-contact-form" :class="isOpen ? '' : 'collapse'" role="tabpanel" aria-labelledby="contact-now">
        <div class="row">
          <div class="col-12 col-md-8 mx-auto">
            <ContactFormComponent />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ContactFormComponent from "@/components/ContactFormComponent";
import { ref } from 'vue';
export default {
  name: 'CallToActionComponent',
  components: {
    ContactFormComponent
  },
  setup () {
    const isOpen = ref(false)

    return {
      isOpen,
      toggleForm: () => {
        isOpen.value = !isOpen.value
      }
    }
  }
}
</script>