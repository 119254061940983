<template>
  <section id="about">
    <div class="container">
      <div class="row">
        <div class="col-12 col-sm-5">
          <p class="featured-text">Contamos con más de 20 años de experiencia en el sector público y privado</p>
        </div>
        <div class="col-12 col-sm-7">
          <swiper-container id="questions" navigation="true" pagination="true" :autoplay="true" loop="true">
            <swiper-slide>
              <p>Defensa legal integral, para una solución eficaz y que mejor convenga a los intereses de nuestros clientes.</p>
            </swiper-slide>
            <swiper-slide>
              <p>Amplia experiencia en litigio Comercial, Financiero, Administrativo en materia de Infraestructura e Inmobiliario; Protección de Datos Personales y Daño Moral.</p>
            </swiper-slide>
            <swiper-slide>
              <p>Seguimiento y verificación de la actividad Societaria de las Empresas, así como la asesoría legal en la consecución de las Relaciones Comerciales.</p>
            </swiper-slide>
            <swiper-slide>
              <p>Asistencia en gestión y trámites en el ámbito Migratorio y Registral.</p>
            </swiper-slide>
            <!-- <swiper-slide>
              <p>¿Tienes mercancía detenida (madera, flora y fauna silvestre, residuos, materiales y sustancias peligrosas) asegurada o decomisada en la aduana por alguna irregularidad ambiental y necesitas liberarla?</p>
            </swiper-slide>
            <swiper-slide>
              <p>¿No sabes si la mercancía que vas a importar o exportar requiere permisos y autorizaciones ambientales?</p>
            </swiper-slide>
            <swiper-slide>
              <p>¿Sabes qué requisitos fitosanitarios necesitas cumplir para importar arboles de navidad a México y qué hacer si les detectan plagas?</p>
            </swiper-slide>
            <swiper-slide>
              <p>¿Perteneces a la industria manufacturera, maquiladora y de servicios de exportación (IMEX) y quieres saber que requisitos ambientales debes cumplir si exportas residuos o materiales peligrosos?</p>
            </divswiper-slide> -->
          </swiper-container>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { register } from 'swiper/element/bundle';

register();

export default {
  name: 'AcercaDeComponent',
}
</script>
