<template>
  <header id="main-header" :class="urlClass()">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="mobile-header">
            <div class="desktop-logo"></div>
            <div class="mobile-logo"></div>

            <button type="button" class="navbar-toggle"
              @click="toggleNav">
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </button>
          </div>

          <div id="main-nav" class="menu-main-menu-container" :class="isNavOpen ? ' collapse show' : ''">
            <ul id="main-nav-menu" class="menu">
              <li id="menu-item-6" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-home menu-item-6">
                <router-link to="/">Inicio</router-link>
              </li>
              <li id="menu-item-5" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-5">
                <router-link to="/#servicios">Servicios</router-link>
              </li>
              <li id="menu-item-4" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-4">
                <router-link to="/#call-to-action">Contacto</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>

    </div>
  </header>
</template>

<script>
import { ref } from 'vue'
import { useRoute } from 'vue-router'
export default {
    name: 'HeaderComponent',
    setup() {
      const route = useRoute()
      const isNavOpen = ref(false)

      return {
        isNavOpen,
        urlClass: () => {
          return route.name === 'aviso-privacidad' || isNavOpen.value === true ? 'active relative' : ''
        },
        toggleNav: () => {
          isNavOpen.value = !isNavOpen.value
        }
      }
    }
}
</script>
