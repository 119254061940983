<template>
  <section id="servicios">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2>Servicios</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-4">
          <ul id="service-nav" class="nav" role="tablist">
            <li><button @click="activateTab('litigio')" :class="isActive('litigio') ? 'active' : ''" type="button">Litigio <i class="fa fa-angle-right"></i></button></li>
            <li><button @click="activateTab('corporativo')" :class="isActive('corporativo') ? 'active' : ''" type="button">Corporativo <i class="fa fa-angle-right"></i></button></li>
          </ul>
        </div>
        <div class="col-12 col-lg-8">
          <div class="tab-content">
            <div class="tab-pane" :class="isActive('litigio') ? 'active' : ''">
              <div class="row">
                <div class="col-12 col-sm-6 col-md-4 col-lg-6 m-b-20">
                  <div class="service-description">
                    <i class="icon-checkmark-fen"></i>
                    <p>Atención en procedimientos Civiles, Mercantiles y de Amparo en materia Comercial.</p>
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-6 m-b-20">
                  <div class="service-description">
                    <i class="icon-checkmark-fen"></i>
                    <p>Juicio de Nulidad y de Amparo contra Leyes y Actos de las Autoridades Administrativas Federales, Estatales y Municipales, así como, litigio en materia Fiscal.</p>
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-6 m-b-20">
                  <div class="service-description">
                    <i class="icon-checkmark-fen"></i>
                    <p>Atención a procedimientos Administrativos instaurados por cualquier Autoridad Administrativa, Federal, Estatal y Municipal.</p>
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-6 m-b-20">
                  <div class="service-description">
                    <i class="icon-checkmark-fen"></i>
                    <p>Juicio de Nulidad ante el Tribunal Federal de Justicia Administrativa, y Tribunales Contenciosos Estatales.</p>
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-6 m-b-20">
                  <div class="service-description">
                    <i class="icon-checkmark-fen"></i>
                    <p>Concursos Mercantiles y Reestructuras de Créditos.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane" :class="isActive('corporativo') ? 'active' : ''">
              <div class="row">
                <div class="col-12 col-sm-6 col-md-4 col-lg-6 m-b-20">
                  <div class="service-description">
                    <i class="icon-checkmark-fen"></i>
                    <p>Asesoría en la elaboración y formulación de Convenios y cualquier tipo de acuerdo en materia Empresarial.</p>
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-6 m-b-20">
                  <div class="service-description">
                    <i class="icon-checkmark-fen"></i>
                    <p>Asesoría y seguimiento en materia de Asociaciones y Acuerdos Societarios, así como, en la documentación corporativa de la Sociedad.</p>
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-6 m-b-20">
                  <div class="service-description">
                    <i class="icon-checkmark-fen"></i>
                    <p>Actas de Asamblea y Aspectos Corporativos.</p>
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-6 m-b-20">
                  <div class="service-description">
                    <i class="icon-checkmark-fen"></i>
                    <p>Asesoría para Arrendadoras y Entidades Financieras.</p>
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-6 m-b-20">
                  <div class="service-description">
                    <i class="icon-checkmark-fen"></i>
                    <p>Fusiones, Escisiones y Liquidaciones.</p>
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-6 m-b-20">
                  <div class="service-description">
                    <i class="icon-checkmark-fen"></i>
                    <p>Gestión y trámites Migratorios, Registro Público de la Propiedad y de Comercio.</p>
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-6 m-b-20">
                  <div class="service-description">
                    <i class="icon-checkmark-fen"></i>
                    <p>Verificación ambiental fase 1 (ESA).</p>
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-6 m-b-20">
                  <div class="service-description">
                    <i class="icon-checkmark-fen"></i>
                    <p>Inmobiliario.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from 'vue'
export default {
    name: 'ServiciosComponent',
    setup() {
      const tabName = ref('litigio')

      return {
        tabName,
        isActive: (name) => {
          return tabName.value === name
        },
        activateTab: (name) => tabName.value = name
      }
    }
}
</script>
