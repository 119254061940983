<template>
  <section id="hero-unit">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="description">
            <h1>Consultoría Corporativa y Contenciosa en Materia Comercial y Administrativa</h1>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
    name: 'HeroUnitComponent'
}
</script>
