<template>
  <section id="campos-de-accion">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2>Campos de Acción</h2>
        </div>
      </div>

      <div class="row">

          <div class="col-12 col-sm-6 col-md-4"
            v-for="(item, index) in data" :key="index">
            <div class="campo-de-accion" @click="onOpenModal(item.title, item.description)">
              <div class="thumbnail">
                <img :src="item.image">
                <!-- <img :src="imageUrl(item.image)"> -->
                <h3>{{ item.title }}</h3>
              </div>
              <div class="description">
                {{ item.description }}
              </div>
            </div>
          </div>

      </div>
    </div>
  </section>

  <ModalComponent v-if="isOpen"
    @on:close="onCloseModal"
    :modalTitle="modalTitle"
    :modalDescription="modalDescription"
    />
</template>

<script>
import ModalComponent from "@/components/ModalComponent";
import camposAccion from "@/data/camposAccion";
import { ref } from 'vue';

export default {
    name: 'CamposAccionComponent',
    components: {
        ModalComponent
    },
    setup() {
        const data = ref(camposAccion)
        const isOpen = ref(false)
        const modalTitle = ref('')
        const modalDescription = ref('')

        return {
            data,
            modalTitle,
            modalDescription,
            isOpen,
            onOpenModal: (title, description) => {
              isOpen.value = true
              modalTitle.value = title
              modalDescription.value = description
            },
            onCloseModal: () => {
              isOpen.value = false
            }
        }
    }
}
</script>
