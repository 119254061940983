<template>
    <HeaderComponent />
    <div class="main-container">
        <router-view />
    </div>
    <FooterComponent />
</template>

<script>
import HeaderComponent from '@/components/HeaderComponent.vue'
import FooterComponent from '@/components/FooterComponent.vue'

export default {
  components: {
    HeaderComponent,
    FooterComponent
  }
}
</script>

<style scoped>
    .main-container {
        min-height: 500px;
    }
</style>