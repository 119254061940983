<template>
    <footer id="main-footer">
    <div class="container">
        <div class="row">
        <aside id="footer-first-col" role="complementary" class="col">
            <div id="text-2" class="widget widget_text">
            <div class="textwidget">
                <img loading="lazy" class="alignnone size-full wp-image-7" src="@/assets/images/logo-ac-azul-capive.png" alt="Logo ac blanco Capive" width="77" height="95">
            </div>
            </div>
        </aside>
        <aside id="footer-second-col" role="complementary" class="col-12 col-md-7">
            <div id="text-3" class="widget widget_text">
            <div class="textwidget">
                <ul>
                <li>
                    <i class="fa fa-map-marker"></i>Xicotencatl 10-A, Colonia San Diego Churubusco, Alcaldia Coyoacan C. P. 04120 Oficina 104 y 105
                </li>
                <li>
                    <i class="fa fa-phone"></i>
                    <a href="tel:5639 7399">5639 7399</a>
                </li>
                <li>
                    <i class="fa fa-linkedin"></i>Capive Abogados
                </li>
                </ul>
            </div>
            </div>
        </aside>
        <aside id="footer-fourth-col" role="complementary" class="col">
            <div id="text-4" class="widget widget_text">
            <div class="textwidget">
                <router-link to="aviso-privacidad#main-header" rel="noopener">Aviso de Privacidad</router-link>
            </div>
            </div>
            <div class="credits">
            <p>
                <span>Capive</span> © 2021 <br>Todos los derechos reservados.
            </p>
            <p>Developed by <a class="fen-website" href="http://frontend.ninja/?utm_source=Capive&amp;utm_medium=backlink&amp;utm_campaign=Client%20Backlink" target="_blank">frontend <span>.ninja</span></a></p>
            </div>
        </aside>
        </div>
    </div>
    </footer>    
</template>

<script>
export default {
    name: 'FooterComponent'
}
</script>
