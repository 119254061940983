export default [
    
    {
        "title": "Litigio Comercial",
        "image": require("@/assets/images/comercial.jpg"),
        "description": "CAPIVE se enfoca en representar una variedad de personas, empresas e industrias a nivel local y estatal (constructoras, servicios médicos, responsabilidades financieras), que se ven involucradas en responsabilidad contractual o extracontractual en materia de negocios."
    },
    {
        "title": "Litigio Financiero",
        "image": require("@/assets/images/financiero.jpg"),
        "description": "CAPIVE tiene amplia experiencia en litigio financiero, especialmente en las ramas bursátil, bancaria y fiduciaria, defendiendo los intereses de las instituciones financieras por reclamaciones e indemnizaciones."
    },
    {
        "title": "Litigio en materia de Infraestructura e Inmobiliario",
        "image": require("@/assets/images/inmobiliario.jpg"),
        "description": "Los abogados de CAPIVE prestan sus servicios a constructoras, asesorándolas en los procedimientos de licitación o invitaciones restringidas, así como adjudicaciones directas.\n Asimismo, cuenta con el conocimiento de la normatividad urbana aplicable y los procesos para la ejecución de obras de infraestructura e inmobiliarias, lo que permite brindar estrategias legales que les brinden herramientas para toma de decisiones, contando dentro de su equipo con peritos expertos en materia de construcción que coadyuvan técnicamente en dicha asesoría; ofreciendo además la posibilidad de litigio en estas materias, en contra de impuestos, derechos, multas y sanciones en esa materia.\n CAPIVE ha prestado sus servicios de asesoría inmobiliaria para la ejecución de Fideicomisos, así como la defensa extrajudicial o judicial de los intereses de las partes en dichos contratos.\n Asimismo, en materia condominal brinda asesoría tanto a los administradores como a los condóminos, en el seguimiento de las obligaciones derivadas de estos regímenes."
    },
    {
        "title": "Datos Personales",
        "image": require("@/assets/images/datos-personales.jpg"),
        "description": "CAPIVE tiene amplia experiencia en procedimientos seguidos ante el INAI; presta servicios de asesoría en el cumplimiento de las obligaciones relacionadas en garantizar el uso adecuado de datos personales, así como el ejercicio y tutela de los derechos de acceso, rectificación, cancelación y oposición a que toda persona tiene."
    },
    {
        "title": "Daño Moral",
        "image": require("@/assets/images/moral.jpg"),
        "description": "CAPIVE se especializa en reclamaciones derivadas de daños a la imagen pública de las empresas o personas físicas, ejercitando y defendiendo acciones por daños ocasionados por publicaciones incorrectas, dolosas o no autorizadas, ya sea de particulares o en contra del gobierno. Asimismo, dicha responsabilidad por daño moral se puede ejercitar por afectaciones a las personas en cuanto a sus sentimientos, creencias, vida privada, configuración y aspectos físicos o vulneración de su libertad.\n CAPIVE defiende los intereses de sus clientes si han sufrido daños o se les demanda haber causado daños de esta naturaleza, por negligencia o dolo de alguna empresa o prestador de servicios, con la finalidad de que puedan tener una compensación económica justa."
    },
    {
        "title": "Gestión y Trámites Migratorios",
        "image": require("@/assets/images/migracion.jpg"),
        "description": "CAPIVE presta servicios migratorios para todo tipo de trámites de solicitudes de visas, estancias, visitantes, residencias, constancias de inscripción de empleadores, permisos de trabajo; ante el Instituto Nacional de Migración, para que las personas morales y en general el personal extranjero de las empresas en México y sus familias obtengan el estatus migratorio correcto en México."
    },
    {
        "title": "Pre Auditoría Ambiental en Fase 1",
        "image": require("@/assets/images/consultoria-ambiental.jpg"),
        "description": "Los abogados de CAPIVE tienen experiencia en auditorías ambientales de primera fase. La auditoría ambiental constituye un instrumento jurídico para evaluar el cúmulo de obligaciones ambientales asociados a un inmueble. Este instrumento resulta útil para determinar la viabilidad para el desarrollo de un proyecto o bien para determinar y gestionar posibles contingencias asociadas a un inmueble."
    },
    {
        "title": "Relaciones Societarias y Asesoría Legal en Materia Comercial",
        "image": require("@/assets/images/societarias.jpg"),
        "description": "CAPIVE ofrece servicios de asesoría comercial con el fin de evitar o reducir los riesgos de nuestros clientes en sus operaciones como empresa.\n Ofrecemos los servicios de control legal en materia societaria que permiten contar con los documentos legales necesarios que protejan las relaciones entre socios y administradores, en cualquier forma de vehículo asociativo, entregándonos de la constitución, seguimiento y disolución de negocios.\n Asimismo, ofrecemos servicios que soporten las operaciones que nuestros clientes comerciantes realizan con terceros, no sólo para asegurar sus intereses comerciales, sino también soportar sus operaciones en caso de auditorías gubernamentales, contando con el servicio de dictaminación en el cumplimiento de obligaciones fiscales."
    },
    {
        "title": "Responsabilidades Administrativas",
        "image": require("@/assets/images/asesoria-comercial.jpg"),
        "description": "CAPIVE cuenta con una rama de defensa de los intereses de nuestros clientes en tratándose de procedimientos seguidos en su contra por autoridades administrativas por responsabilidades en diversas materias, en las que se involucre riesgos de multas, suspensiones, clausuras, inhabilitaciones, entre otras afectaciones administrativas. Las materias de su especialidad es en el ámbito de la construcción, servicios médicos, licitaciones y responsabilidad de servidores públicos."
    }
]