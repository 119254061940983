<template>
    <form @submit.prevent="onSubmit">

        <p class="phone">Llámanos al <a href="tel:5556397399">55 5639 7399</a></p>

        <p>o llena el siguiente formulario y te contactaremos para programar tu cita</p>

        <div class="row">
            <div class="col-12 col-sm-6 mb-3">
                <input type="text" name="your-name" size="40"  placeholder="Nombre*" required
                    class="form-control"
                    v-model="name"
                    >
            </div>
            <div class="col-12 col-sm-6 mb-3">
                <input type="email" name="your-email" size="40"  placeholder="Email*"  required
                    class="form-control"
                    v-model="email"
                    >
            </div>
            <div class="col-12 col-sm-6 mb-3">
                <input type="text" name="your-phone" size="40"  placeholder="Teléfono*" 
                    class="form-control" required
                    v-model="phone"
                    >
            </div>
            <div class="col-12 col-sm-6 mb-3">
                <input type="text" name="your-subject" size="40"  placeholder="Asunto" 
                    class="form-control"
                    v-model="subject"
                    >
            </div>
            <div class="col-12 col-sm-6 mb-3">
                <input type="text" name="your-company" size="40"  placeholder="Empresa" 
                    class="form-control mb-3"
                    v-model="company"
                    >

                <input type="text" name="your-position" size="40"  placeholder="Puesto" 
                    class="form-control"
                    v-model="charge"
                    >
            </div>
            <div class="col-12 col-sm-6 mb-3">
                <textarea name="your-message" cols="40" rows="4" placeholder="Mensaje" 
                    class="form-control"
                    v-model="message"
                    ></textarea>
            </div>
            <div class="col-12 col-sm-6 mb-3 t-left">
                <p class="required-text">* Campos requeridos.</p>
            </div>
            <div class="col-12 col-sm-6 mb-3 t-right">
                <input type="submit" value="Enviar" class="btn btn-primary">
            </div>
        </div>
    </form>
</template>

<script>
import axios from "axios";
import { ref } from 'vue';
import Swal from 'sweetalert2'

export default {
    name: 'ContactFormComponent',
    setup() {
        const name = ref('')
        const email = ref('')
        const phone = ref('')
        const subject = ref('')
        const company = ref('')
        const charge = ref('')
        const message = ref('')
        const userResponse = ref('')

        return {
            name,
            email,
            phone,
            subject,
            company,
            charge,
            message,
            userResponse,
            onSubmit: async() => {

                const hasRequiredFields = name.value.length === 0 || email.value.length === 0 || phone.value.length === 0

                if (hasRequiredFields) {
                    return Swal.fire({
                        title: 'Es necesario agregar los datos requeridos',
                        icon: 'error',
                    })                    
                }

                Swal.fire({
                    title: 'Espere por favor',
                    allowOutsideClick: false
                })

                Swal.showLoading()

                try {
                    const { data } = await axios.post('https://api.web3forms.com/submit', {
                        "access_key": process.env.VUE_APP_WEB_3_FORMS_ACCESS_KEY,
                        "name": name.value,
                        "email": email.value,
                        "phone": phone.value,
                        "subject": subject.value,
                        "company": company.value,
                        "charge": charge.value,
                        "message": message.value
                    })

                    if (data.success) {
                        Swal.fire({
                            title: '¡Mensaje Enviado!',
                            icon: 'success',
                            text: 'Gracias, nos pondremos en contacto contigo tan pronto nos sea posible.'
                        })
                    } else {
                        console.log('data', data);
                    }

                } catch (error) {
                    Swal.fire({
                        title: 'Algo salió mal…',
                        icon: 'error',
                        text: error.message
                    })

                    console.log('error', error);
                }
            }
        }
    }
}
</script>
