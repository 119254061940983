<template>
  <HeroUnitComponent />
  <AcercaDeComponent />
  <ServiciosComponent />
  <CamposAccionComponent />
  <CallToActionComponent />
</template>

<script>
import HeroUnitComponent from "@/components/HeroUnitComponent";
import AcercaDeComponent from "@/components/AcercaDeComponent";
import ServiciosComponent from "@/components/ServiciosComponent";
import CamposAccionComponent from "@/components/CamposAccionComponent";
import CallToActionComponent from "@/components/CallToActionComponent";

export default {
  name: 'HomeView',
  components: {
    HeroUnitComponent,
    AcercaDeComponent,
    ServiciosComponent,
    CamposAccionComponent,
    CallToActionComponent
  }
}
</script>
