<template>
    <div class="modal" tabindex="-1"
        @click.self="$emit('on:close')"
        @keypress.esc="$emit('on:close')"
        @keydown="$emit('on:close')"
        >
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ modalTitle }}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    @click="$emit('on:close')"></button>
            </div>
            <div class="modal-body">
                {{ modalDescription }}
            </div>
            </div>
        </div>
        </div>
    <div class="modal-backdrop fade show"
        @click.self="$emit('on:close')"></div>
</template>

<script>
export default {
    name: 'ModalComponent',
    props: ['modalTitle', 'modalDescription'],
    emits: ['on:close']
}
</script>
<style scoped>
.modal {
    display: block;
}
</style>